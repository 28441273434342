import React from 'react'

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-294 415.9 7 10">
    <path
      fill="currentColor"
      d="M-289.9,425.9c0.9,0,1.9,0,2.9,0c-1.4-1.7-2.7-3.3-4.1-5c1.4-1.7,2.7-3.3,4.1-5c-0.9,0-1.9,0-2.9,0 c-1.3,1.5-2.6,3.2-4.1,5C-292.8,422.4-291.3,424.2-289.9,425.9z"
    />
  </svg>
)
