import { navigate, graphql, StaticQuery } from 'gatsby';
import md5 from 'md5';
import React from 'react';

import { Form, FormError, FormHeader, FormItems, FormSubmit } from '../components/form';
import Input from '../components/input';
import Layout from '../components/layout';
import { Content, ContentDesc, Footer, FooterLinkBack, Header, Page } from '../components/page';
import Seo from '../components/seo';
import SvgArrowLeft from '../svg/arrowLeft';
import getString from '../utils/get-string';

class ResetPass extends React.Component {
  state = {
    form: {},
    submitProcessing: false,
    user: null,
    passwordsAreSame: true,
  }

  submitHandler = event => {
    this.setState({
      submitProcessing: !!this.validatePassword(),
      passwordsAreSame: this.validatePassword(),
    })

    if (this.state.user && this.validatePassword()) this.resetPassword()
  }

  resetPassword = () => {
    const userId = this.state.user

    fetch(`https://site-api.datocms.com/items/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Version': 2,
        Accept: 'application/json',
        Authorization: `Bearer 186716fd2040d981a07945e7a0f0de`,
      },
      body: JSON.stringify({
        data: {
          type: 'item',
          id: '47741',
          attributes: {
            password: this.state.form.password,
          },
        },
      }),
    })
      .then(response => {
        this.setState({ responseOk: response.ok })
        return response
      })
      .then(res => res.json())
      .then(data => {
        if (this._isMounted) {
          return this.state.responseOk
            ? this.handleSuccessPasswordReset(data)
            : this.setState({ user: false, submitProcessing: false })
        }
      })
  }

  handleSuccessPasswordReset = ({ data }) => {
    if (!data.attributes.emailisverified) {
      navigate(`/email-not-confirmed`, { state: { ...data } })
      return
    }

    navigate(`/reset-password-affirmation`)
  }

  validatePassword = () =>
    this.state.form.password === this.state.form['password-repeat']
      ? true
      : false

  componentWillUnmount() {
    this._isMounted = false
  }

  checkUserExists = () => {
    const userId = window.location.hash.substring(1)

    return this.setState({ user: userId ? userId : undefined })
  }

  handleSuccessLogin = userData => {}

  componentDidMount() {
    this._isMounted = true
    this.checkUserExists()
  }

  handleChange = event => {
    const name = event.target.name
    const value =
      event.target.type !== 'checkbox' || event.target.type !== 'radio'
        ? event.target.type === 'password'
          ? md5(event.target.value)
          : event.target.value
        : event.target.checked

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  render() {
    return (
      <Layout>
        <Seo title={getString(this.props.strings, "11220922")} />

        <Page>
          <Form onSubmit={this.submitHandler}>
            <Header>{getString(this.props.strings, "11220916")}</Header>
            <Content>
              <FormHeader>
                <ContentDesc>{getString(this.props.strings, "11220916")}</ContentDesc>
                <h1>{getString(this.props.strings, "11220922")}</h1>
              </FormHeader>

              <FormItems>
                <dt>
                  <label htmlFor="password">{getString(this.props.strings, "11249005")}</label>
                </dt>
                <dd>
                  <Input
                    size="lg"
                    id="password"
                    type="password"
                    name="password"
                    required={true}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>

                <dt>
                  <label htmlFor="password-repeat">
                    {getString(this.props.strings, "11249011")}
                  </label>
                </dt>
                <dd>
                  <Input
                    size="lg"
                    id="password-repeat"
                    type="password"
                    name="password-repeat"
                    required={true}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>
              </FormItems>

              {this.state.user !== null && !this.state.user && (
                <FormError>{getString(this.props.strings, "11220924")}</FormError>
              )}

              {!this.state.passwordsAreSame && (
                <FormError>{getString(this.props.strings, "11249013")}</FormError>
              )}
            </Content>
            <Footer>
              <FormSubmit disabled={this.state.submitProcessing}>
                {getString(this.props.strings, "11249014")}
              </FormSubmit>
              <FooterLinkBack to="/login">
                <SvgArrowLeft />
                {getString(this.props.strings, "11249224")}
              </FooterLinkBack>
            </Footer>
          </Form>
        </Page>
      </Layout>
    )
  }
}



export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220922", "11220916", "11249005", "11220910", "11249011", "11220924", "11249013", "11249014", "11249224"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <ResetPass {...data} {...props} />}
  />
)
